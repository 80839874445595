<script setup>
const props = defineProps(["filter", "filtersStore"]);

const selectedSlug = ref(props.filtersStore.selected_filters[props.filter.slug]?.value?.slug || '');
const searchInputValue = ref('');

onMounted(() => {
  selectedSlug.value = props.filtersStore.selected_filters[props.filter.slug]?.value;
})

watch(searchInputValue, () => {
  const regex = new RegExp(searchInputValue.value, 'i');
  filteredAllData.value = showAllData.value.filter(item => regex.test(item.value));
})

const updateValue = (filter, row) => {
  isMore.value = false;
  if (selectedSlug.value === row.slug) {
    selectedSlug.value = null;
    props.filtersStore.updateValue(filter, null);
  } else {
    selectedSlug.value = row.slug;
    props.filtersStore.updateValue(filter, row);
  }
};

const showData = () => {
  if (searchInputValue.value) {
    if (filteredAllData.value.length) {
      return filteredAllData.value;
    }else {
      return [];
    }
  } else {
    return showAllData.value
  }
}

const isMore = ref(false);
const showLimitedData = ref(props.filter.data_source.slice(0, 5));
const showAllData = ref(props.filter.data_source);
const filteredAllData = ref(props.filter.data_source);

const searchInArray = (array) => {
  return array.find((item) => item.slug == selectedSlug.value)
}

const foundInAllData = computed(() => searchInArray(showAllData.value));
const foundInLimitedData = computed(() => searchInArray(showLimitedData.value));
</script>

<template>
  <div class="border-b pb-2 my-3">
    <h3 class="text-lg font-medium text-indigo-900 leading-normal">{{ filter.name }}</h3>

    <div class="w-full flex flex-wrap gap-2 my-3">
      <div class="shrink-0 relative" v-for="item in showLimitedData">
        <button @click="updateValue(filter, item)" class="rounded-2xl p-3 block" :class="{
          [item.slug == selectedSlug ? 'active-type' : 'bg-white']: true
        }">
          <div class="flex justify-center items-center">
            <i v-if="item.icon" :class="item.icon" class="text-indigo-900 me-2"></i>
            <h4 class="text-sm">{{ item.value }}</h4>
          </div>
        </button>
      </div>
      <div class="shrink-0 relative" v-if="foundInAllData && !foundInLimitedData">
        <button class="rounded-2xl p-3 block active-type">
          <div class="flex justify-center items-center">
            <i v-if="foundInAllData?.icon" :class="foundInAllData?.icon" class="text-indigo-900 me-2"></i>
            <h4 class="text-sm">{{ foundInAllData?.value }}</h4>
          </div>
        </button>
      </div>

      <w-slideover v-model="isMore" :ui="{
        wrapper: 'bottom-0 top-1/4',
        width: 'max-w-full',
        height: 'h-4/5',
        translate: {
          base: '-translate-y-0 translate-x-0',
          right: 'translate-y-full rtl:-translate-y-full translate-x-0 rtl:-translate-x-0'
        },
        rounded: 'rounded-xl',
        overlay: {
          background: 'bg-black/20 opacity-50'
        }
      }">
        <div class="flex items-center p-3">
          <h3 class="text-lg font-medium text-indigo-900 leading-normal">{{ filter.name }}</h3>
          <div class="ms-auto" @click="isMore = false"><i class="fa-solid fa-close fa-xl"></i></div>
        </div>

        <div class="relative p-3">
          <div class="absolute flex items-center ps-3 inset-y-0">
            <i class="fa-solid fa-search"></i>
          </div>
          <input type="text" v-model="searchInputValue" name="search"
            class="w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 focus-visible:outline-none" />

        </div>
        <div class="overflow-y-auto">
          <div class="shrink-0 relative" v-for="item in showData()">
            <button @click="updateValue(filter, item)" class="rounded-2xl p-3 block ms-2" :class="{
              [item.slug == selectedSlug ? 'active-type' : 'bg-white']: true
            }">
              <div class="flex justify-center items-center">
                <i v-if="item.icon" :class="item.icon" class="text-indigo-900 me-2"></i>
                <h4 class="text-sm">{{ item.value }}</h4>
              </div>
            </button>
          </div>
        </div>
      </w-slideover>
    </div>

    <button @click="isMore = true" v-if="showAllData.length > 5" class="text-indigo-900 text-sm underline">MORE</button>
  </div>
</template>
